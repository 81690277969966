<template>
  <div class="container1" v-loading="loading">
    <div class="top-box">
      <div class="top1">
        <p style="font-size: 18px; color: #434343; margin-left: 30px">总收入</p>
        <p class="top-p">
          <img
            style="height: 30px; width: 30px; margin-right: 6px"
            src="../../assets/imgs/q2.png"
            alt=""
          /><samp>¥ {{ totalData.incomeTotal }}</samp>
        </p>
        <ul class="top-ul1">
          <li>
            <p><span class="span1"></span><span>课程订单</span></p>
            <samp class="span2"
              >¥
              {{
                getValueInList(totalData.incomeList, "study_card").price
              }}</samp
            >
          </li>
          <li>
            <p><span class="span1"></span><span>权益点充值</span></p>
            <samp class="span2"
              >¥ {{ getValueInList(totalData.incomeList, "point").price }}</samp
            >
          </li>
          <li>
            <p><span class="span1"></span><span>消费金购买</span></p>
            <samp class="span2"
              >¥
              {{ getValueInList(totalData.incomeList, "consume").price }}</samp
            >
          </li>
          <li>
            <p><span class="span1"></span><span>会员卡购买</span></p>
            <samp class="span2"
              >¥
              {{ getValueInList(totalData.incomeList, "member").price }}</samp
            >
          </li>
        </ul>
        <ul class="top-ul1 ul-income">
          <li v-for="(item, index) in totalData.incomeList" :key="index">
            <p>
              <span class="span1"></span><span>{{ item.label }}</span>
            </p>
            <samp class="span2">¥ {{ item.price.toFixed(2) }}</samp>
          </li>
        </ul>
      </div>
      <div class="top1" style="border-left: 8px solid #8565ff">
        <p style="font-size: 18px; color: #434343; margin-left: 30px">总支出</p>
        <p class="top-p">
          <img
            style="height: 30px; width: 30px; margin-right: 6px"
            src="../../assets/imgs/q1.png"
            alt=""
          /><samp>¥ {{ totalData.expendTotal }}</samp>
        </p>
        <ul class="top-ul1">
          <li>
            <p>
              <span class="span1" style="background-color: #8565ff"></span
              ><span>退款</span>
            </p>
            <samp class="span2"
              >¥
              {{ getValueInList(totalData.expendList, "refund").price }}</samp
            >
          </li>
          <li>
            <p>
              <span class="span1" style="background-color: #8565ff"></span
              ><span>课程结算</span>
            </p>
            <samp class="span2"
              >¥ {{ getValueInList(totalData.expendList, "结算").price }}</samp
            >
          </li>
          <li>
            <p>
              <span class="span1" style="background-color: #8565ff"></span
              ><span>提现</span>
            </p>
            <samp class="span2"
              >¥
              {{ getValueInList(totalData.expendList, "transfer").price }}</samp
            >
          </li>
          <li>
            <p>
              <span class="span1" style="background-color: #8565ff"></span
              ><span>需求结算</span>
            </p>
            <samp class="span2"
              >¥
              {{
                getValueInList(totalData.expendList, "教练需求结算").price
              }}</samp
            >
          </li>
        </ul>
        <ul class="top-ul1 ul-income">
          <li v-for="(item, index) in totalData.expendList" :key="index">
            <p>
              <span class="span1" style="background-color: #8565ff"></span
              ><span>{{ item.label }}</span>
            </p>
            <samp class="span2">¥ {{ item.price.toFixed(2) }}</samp>
          </li>
        </ul>
      </div>
      <div class="top-right">
        <div class="box-nr">
          <div class="imgbox">
            <img class="imgc" src="../../assets/imgs/uasntp/1s.png" />
          </div>
          <ul>
            <li class="top-li">用户总数</li>
            <li>{{ totalInfo.userCount }}</li>
          </ul>
        </div>
        <div class="box-nr">
          <div class="imgbox">
            <img class="imgc" src="../../assets/imgs/uasntp/7s.png" />
          </div>
          <ul>
            <li class="top-li">会员卡</li>
            <li>{{ totalInfo.userMemberCount || 0 }}</li>
          </ul>
        </div>
        <div class="box-nr">
          <div class="imgbox">
            <img class="imgc" src="../../assets/imgs/uasntp/3s.png" />
          </div>
          <ul>
            <li class="top-li">机构</li>
            <li>{{ totalInfo.mechanismCount || 0 }}</li>
          </ul>
        </div>
        <div class="box-nr">
          <div class="imgbox">
            <img class="imgc" src="../../assets/imgs/uasntp/5s.png" />
          </div>
          <ul>
            <li class="top-li">课程</li>
            <li>{{ totalInfo.courseCount || 0 }}</li>
          </ul>
        </div>
        <div class="box-nr">
          <div class="imgbox">
            <img class="imgc" src="../../assets/imgs/uasntp/coach.png" />
          </div>
          <ul>
            <li class="top-li">教练</li>
            <li>{{ totalInfo.coachCount || 0 }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="nr-z">
      <div class="nr-s">
        <el-date-picker
          v-model="date"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          @change="changeTime"
        >
        </el-date-picker>
        <p style="width: 50px"></p>
        <el-tabs v-model="activeName" @tab-click="tabChange">
          <el-tab-pane
            v-for="(item, index) in tabslist"
            :key="index"
            :label="item"
            :value="index"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div class="nrx-box">
        <div class="nr-x">
          <p class="nr-x1">新增用户数</p>
          <p class="nr-x2">
            <img src="../../assets/imgs/uasntp/1.png" alt="" /><samp
              style="color: #527498"
              >{{ userCount.newCount || 0 }}</samp
            >
          </p>
        </div>
        <div class="nr-x">
          <p class="nr-x1">活跃用户数</p>
          <p class="nr-x2">
            <img src="../../assets/imgs/uasntp/2.png" alt="" /><samp
              style="color: #8565ff"
              >{{ userCount.onlineCount || 0 }}</samp
            >
          </p>
        </div>
        <div class="nr-x" @click="topage('OrderDataBoard')">
          <el-popover width="250" trigger="hover">
            <div slot="reference">
              <p class="nr-x1">{{ tabName || "" }}总收入</p>
              <p class="nr-x2">
                <img src="../../assets/imgs/uasntp/3.png" alt="" /><samp
                  style="color: #ff7e00"
                  >¥ {{ todayData.incomeTotal }}</samp
                >
              </p>
            </div>
            <ul>
              <li
                class="li-item"
                v-for="(item, index) in todayData.incomeList"
                :key="index"
              >
                <span>{{ item.label }}</span>
                <span>¥ {{ item.price }}</span>
              </li>
            </ul>
            <p v-if="todayData.incomeList.length == 0">暂无订单</p>
          </el-popover>
        </div>
        <div class="nr-x" @click="topage('expendOrderList')">
          <el-popover width="250" trigger="hover">
            <div slot="reference">
              <p class="nr-x1">{{ tabName || "" }}总支出</p>
              <p class="nr-x2">
                <img src="../../assets/imgs/uasntp/4.png" alt="" /><samp
                  style="color: #3d72ff"
                  >¥ {{ todayData.expendTotal }}</samp
                >
              </p>
            </div>
            <ul>
              <li
                class="li-item"
                v-for="(item, index) in todayData.expendList"
                :key="index"
              >
                <span>{{ item.label }}</span>
                <span>¥ {{ item.price }}</span>
              </li>
            </ul>
            <p v-if="todayData.expendList.length == 0">暂无订单</p>
          </el-popover>
        </div>
      </div>
    </div>

    <div class="echart-wrap">
      <div class="echart-row">
        <div class="echart-item">
          <div class="echart-title">订单</div>
          <div class="order-line">
            <barEchart ename="orderbar" :data="orderLineData"></barEchart>
          </div>
        </div>
      </div>

      <div class="echart-row grid-2">
        <div class="echart-item">
          <div class="echart-title">新增教练</div>
          <div class="order-line">
            <barEchart ename="coachbar" :data="coachBarData"></barEchart>
          </div>
        </div>
        <div class="echart-item">
          <div class="echart-title">新增会员</div>
          <div class="order-line">
            <barEchart ename="vipcoachbar" :data="vipCoachBarData"></barEchart>
          </div>
        </div>
      </div>

      <div class="echart-row flex">
        <div class="echart-item">
          <div class="echart-title">机构</div>
          <div class="mec-line">
            <line-echart ename="mecline" :data="mecLineData"></line-echart>
          </div>
        </div>

        <div class="course-wrap">
          <div class="echart-title">课程</div>
          <div class="course-item flex">
            <div class="course-left">
              <img src="@/assets/imgs/time.png" class="course-img" alt="" />
            </div>
            <div class="course-right">
              <div class="name">预约上课</div>
              <div class="num">{{AppointmentCount.total || 0}}</div>
            </div>
          </div>

          <div class="course-item flex">
            <div class="course-left">
              <img src="@/assets/imgs/time.png" class="course-img" alt="" />
            </div>
            <div class="course-right">
              <div class="name">已核销</div>
              <div class="num">{{AppointmentCount.settleCount || 0}}</div>
            </div>
          </div>

          <div class="course-item flex">
            <div class="course-left">
              <img src="@/assets/imgs/time.png" class="course-img" alt="" />
            </div>
            <div class="course-right">
              <div class="name">结算金额</div>
              <div class="num">{{AppointmentCount.account || 0}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import "swiper/dist/css/swiper.min.css";
import { getorderType } from "@/utils/map/orderType.js";
import lineEchart from "./components/echarts/lineEchart.vue";
import barEchart from "./components/echarts/barEchart.vue";

export default {
  data() {
    return {
      viewOption: ["nick_name", "mobile", "r_type", "rcharge_time", "amount"],
      list: [],
      dy_list: [],
      is_loading: false,
      activeName: 0,
      listName: ["用户昵称", "用户手机号", "订单类型", "时间", "金额"],
      tabslist: ["今日", "本周", "本月"],
      tabName: "今日",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近7天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      date: "",
      start_time: new Date().Format("yyyy-MM-dd") + " 00:00:00",
      totalData: {
        incomeTotal: 0,
        expendTotal: 0,
        incomeNum: 0,
        expendNum: 0,
        incomeList: [],
        expendList: [],
      },
      todayData: {
        incomeTotal: 0,
        expendTotal: 0,
        incomeNum: 0,
        expendNum: 0,
        incomeList: [],
        expendList: [],
      },
      memberInfo: {},
      userCount: {},
      merCount: 0,
      totalInfo: {},
      coachCount: {},

      AppointmentCount: 0,
      mecCount: {},
      loading: false,

      orderLineData: { xData: [], yData: [] },
      mecLineData: {
        xData: ["新增机构", "新增课包", "新增教练"],
        yData: [0, 0, 0],
      },
      coachBarData: {
        xData: [],
        yData: [],
      },
      vipCoachBarData: {
        xData: [],
        yData: [],
      },
    };
  },
  components: {
    lineEchart,
    barEchart,
  },
  created() {
    this.assets_total();
    this.total_count();
    this.statisticsByTime();
  },
  mounted() {
    this.loading = true;
  },
  methods: {
    topage(page) {
      this.$router.push({
        name: page,
      });
    },
    //按时间段的统计方法
    statisticsByTime() {
      this.user_count();
      this.assetsByTime();
      this.coachGroup();
      this.coachVipGroup();
      this.mechanism_count();
      this.course_count();
    },
    Paytype(type) {
      return getorderType(type).label;
    },

    // 时间选择
    changeTime() {
      this.activeName = "4";
      this.statisticsByTime();
      this.tabName = "";
    },
    // tab切换
    tabChange(val) {
      this.date = [];
      this.tabName = val.label;
      switch (val.label) {
        case "今日":
          this.start_time = new Date().Format("yyyy-MM-dd") + " 00:00:00";
          break;
        case "本周":
          this.start_time = this.getNowWeek();
          break;
        case "本月":
          this.start_time = this.getCurrentMonthFirst();
          break;
        default:
          break;
      }
      this.statisticsByTime();
    },
    //本月
    getCurrentMonthFirst() {
      var date = new Date();
      return new Date(date.setDate(1)).Format("yyyy-MM-dd") + " 00:00:00";
    },
    // 获取本周
    getNowWeek(s) {
      let curDay = s ? new Date(s).getDay() : new Date().getDay();
      let start = s ? new Date(s).getTime() : new Date().getTime();
      let week = [];
      for (let i = 1 - curDay; i < 8 - curDay; i++) {
        let time = new Date(start + i * 1000 * 24 * 60 * 60);
        let data = {
          week: time.getDay(),
          day: time.getDate(),
          time: time.format("yyyy-MM-dd"),
        };
        week[data.week] = data;
      }
      return week[1].time + " 00:00:00";
    },
    //注册用户统计
    total_count() {
      console.log("all");
      let url = "user/rechargerecord/queryTotalAll";
      let params = { start_time: this.start_time };
      this.$axios({
        url,
        params,
        method: "get",
      }).then((res) => {
        this.totalInfo = res.data.data;
      });
    },
    //机构统计
    mechanism_count() {
      let url = "user/mastermechanism/queryCountByTime";
      let params = {
        start_range: this.start_time,
        end_range: new Date().Format("yyyy-MM-dd") + " 23:59:59",
      };
      if (this.date && this.date.length > 0) {
        params.start_range = this.date[0] + " 00:00:00";
        params.end_range = this.date[1] + " 23:59:59";
      }

      this.$axios.get(url, { params }).then((res) => {
        this.mecCount = res.data.data;
        let yData = [
          this.mecCount.mechanismCount,
          this.mecCount.courseCount,
          this.mecCount.coachCount,
        ];
        this.mecLineData.yData = yData;
      });
    },

    //教练统计
    coachGroup() {
      let url = "user/coach/categoryGroup";
      let params = {
        status: 2,
        start_time: this.start_time,
        end_time: new Date().Format("yyyy-MM-dd") + " 23:59:59",
      };
      if (this.date && this.date.length > 0) {
        params.start_time = this.date[0] + " 00:00:00";
        params.end_time = this.date[1] + " 23:59:59";
      }

      this.$axios.get(url, { params }).then((res) => {
        res.data.data.list.sort((a, b) => b.count - a.count);
        let list = res.data.data.list;
        let xData = [];
        let yData = [];
        let other = 0;
        for (let i = 0; i < list.length; i++) {
          if (i < 9) {
            xData.push(list[i].cate);
            yData.push(list[i].count);
          } else {
            other += parseInt(list[i].count);
          }
        }
        if (list.length >= 10) {
          xData.push("其他");
          yData.push(other);
        }
        if (yData.length > 0) {
          this.coachBarData = { xData, yData };
        }else{
          this.coachBarData = {xData: ["篮球", "体适能", "健身", "游泳"],
           yData: [0, 0, 0, 0],}
        }
      });
    },
    //教练会员统计
    coachVipGroup() {
      let url = "user/coach/categoryGroup";
      let params = {
        status: 2,
        identity_type: 2,
        start_time: this.start_time,
        end_time: new Date().Format("yyyy-MM-dd") + " 23:59:59",
      };
      if (this.date && this.date.length > 0) {
        params.start_time = this.date[0] + " 00:00:00";
        params.end_time = this.date[1] + " 23:59:59";
      }

      this.$axios.get(url, { params }).then((res) => {
        res.data.data.list.sort((a, b) => b.count - a.count);
        let list = res.data.data.list;
        let xData = [];
        let yData = [];
        let other = 0;
        for (let i = 0; i < list.length; i++) {
          if (i < 9) {
            xData.push(list[i].cate);
            yData.push(list[i].count);
          } else {
            other += parseInt(list[i].count);
          }
        }
        if (list.length >= 10) {
          xData.push("其他");
          yData.push(other);
        }
        if (yData.length > 0) {
          this.vipCoachBarData = { xData, yData };
        }else{
          this.vipCoachBarData = {xData: ["篮球", "体适能", "健身", "游泳"],
           yData: [0, 0, 0, 0],}
        }
      });
    },

    //课程统计
    course_count() {
      let url = "user/masterAppointment/settleCountByTime";
      let params = {
        start_time: this.start_time,
        end_time: new Date().Format("yyyy-MM-dd") + " 23:59:59",
      };
      if (this.date && this.date.length > 0) {
        params.start_time = this.date[0] + " 00:00:00";
        params.end_time = this.date[1] + " 23:59:59";
      }

      this.$axios.get(url, { params }).then((res) => {
        this.AppointmentCount = res.data.data;
      });
    },

    //注册用户统计
    user_count() {
      let url = "user/user/userNewAndOnline";

      let params = {
        start_time: this.start_time,
        end_time: new Date().Format("yyyy-MM-dd") + " 23:59:59",
      };
      if (this.date && this.date.length > 0) {
        params.start_time = this.date[0] + " 00:00:00";
        params.end_time = this.date[1] + " 23:59:59";
      }
      this.$axios.get(url, { params }).then((res) => {
        this.userCount = res.data.data;
      });
    },
    //总资产统计
    assets_total() {
      let url = "user/rechargerecord/totalAssetsCount";
      let params = {
        // statistics_time: new Date().Format("yyyy-MM-dd hh:mm:ss"),
        start_time: "2021-01-01 00:00:00",
        end_time: new Date().Format("yyyy-MM-dd") + " 24:00:00",
      };
      this.$axios
        .get(url, { params: params })
        .then((res) => {
          this.loading = false;
          let result = res.data.data;
          let resTotal = result.statisticsTotal;
          this.totalData = this.handlerData(resTotal);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //按时间统计总资产
    assetsByTime() {
      let url = "user/rechargerecord/totalAssetsCount";
      let params = {
        // statistics_time: new Date().Format("yyyy-MM-dd hh:mm:ss"),
        start_time: this.start_time,
        end_time: new Date().Format("yyyy-MM-dd") + " 24:00:00",
      };
      if (this.date && this.date.length > 0) {
        params.start_time = this.date[0] + " 00:00:00";
        params.end_time = this.date[1] + " 24:00:00";
      }
      this.$axios.get(url, { params: params }).then((res) => {
        let result = res.data.data;
        let resTotal = result.statisticsTotal;
        this.todayData = this.handlerData(resTotal);
        this.handlerOrderEcharts();
      });
    },

    handlerOrderEcharts() {
      let list = this.todayData.incomeList;
      let xData = [
        "机构课程",
        "教练课程",
        "会员卡",
        "课程结算",
        "退款",
      ];
      let yData = [0, 0, 0, 0, 0];
      this.todayData.incomeList.forEach((item) => {
        if (
          [
            "study_card",
            "studycard_single",
            "course_single_appo",
            "course_single_fixed",
          ].includes(item.value)
        ) {
          yData[0] += item.price;
        }
        if (["need_bond", "need_one_pay"].includes(item.value)) {
          yData[1] += item.price;
        }
        if (["attestation_coach"].includes(item.value)) {
          yData[2] += item.price;
        }
      });
      this.todayData.expendList.forEach((item) => {
        if (["教练需求结算", "结算"].includes(item.value)) {
          yData[3] -= item.price;
        }
        if (["refund"].includes(item.value)) {
          yData[4] -= item.price;
        }
      });

      yData = yData.map((v) => v.toFixed(2));
      this.orderLineData = {
        xData,
        yData,
      };
    },

    handlerData(list) {
      let incomeTotal = 0;
      let expendTotal = 0;
      let incomeNum = 0;
      let expendNum = 0;
      let incomeList = [];
      let expendList = [];
      list.forEach((item) => {
        let type = getorderType(item.rcharge_type);
        type.num = item.total_num;
        type.price = item.total_Acount;
        if (!type.notQuery) {
          if (type.income) {
            incomeTotal += item.total_Acount;
            incomeNum += item.total_num;
            incomeList.push(type);
          } else {
            expendTotal += item.total_Acount;
            expendNum += item.total_num;
            expendList.push(type);
          }
        }
      });
      return {
        incomeTotal: incomeTotal.toFixed(2),
        expendTotal: expendTotal.toFixed(2),
        incomeNum: incomeNum.toFixed(0),
        expendNum: expendNum.toFixed(0),
        incomeList,
        expendList,
      };
    },
    getValueInList(list, name) {
      let arr = list.filter((item) => {
        if (item.value == name) {
          return true;
        }
      });
      return arr[0] || { price: 0, num: 0, label: "" };
    },
  },
};
</script>
  <style lang="less" >
.container1 {
  background-color: #f8f8f8;
  .mad-wrap {
    @media screen and(max-width: 1280px) {
      width: 100% !important;
    }
  }
  .echart-wrap {
    padding: 20px;
    background-color: #fff;
  }
  .echart-item {
    background-color: #fff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    flex: 1;
    padding: 0 10px;
  }
  .echart-title {
    font-size: 26px;
    font-weight: 400;
    color: #4c4d4f;
    padding: 20px 0 0 40px;
  }

  .echart-row {
    padding: 10px;
    width: 100%;
  }
  .mec-line {
    height: 380px;
    width: 100%;
  }
  .order-line {
    height: 400px;
    width: 100%;
  }
  .grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .course-wrap {
    // width: 440px;
    margin-left: 30px;
    background-color: #fff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    padding: 0 20px;

    .course-item {
      width: 400px;
      height: 100px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      border: 4px solid #ffe5b7;
      margin-top: 10px;
    }
    .course-left {
      width: 100px;
      height: 100px;
      background: #ffd58a;
      border-radius: 6px 0 0 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .course-img {
      width: 60px;
      height: 60px;
    }
    .course-right {
      padding: 10px 0 0 16px;
    }
    .name {
      font-size: 24px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #5a5a5a;
    }
    .num {
      font-size: 34px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #ff7e00;
    }
  }

  .top-box {
    background-color: #f7f7f7;
    margin-right: 10px;
    justify-content: space-between;
    display: flex;
    width: 100%;
    @media screen and(max-width: 1280px) {
      flex-wrap: wrap;
    }
    .top1 {
      position: relative;
      background-color: #ffffff;
      padding: 40px 40px 0 25px;
      font-size: 18px;
      min-height: 230px;
      width: 300px;
      border-left: 8px solid #ff7e00;
      margin-right: 20px;
      @media screen and(max-width: 1280px) {
        flex: 1;
      }
      &:hover .ul-income {
        display: block;
      }
    }
    .top-p {
      margin-top: 20px;
      margin-left: 30px;
      display: flex;
      align-items: center;
      font-size: 26px;
      color: #434343;
    }
    .ul-income {
      position: absolute;
      top: 124px;
      background-color: #fff;
      display: none;
      padding: 0 16px 10px 16px;
      border-radius: 4px;
      width: 100%;
      box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.1);
      z-index: 999;
    }
    .top-ul1 {
      padding-left: 10px;
      font-size: 14px;
      margin-left: 30px;
      @media screen and(max-width: 1280px) {
        margin-left: 0;
      }

      > li {
        display: flex;
        align-items: center;
        color: #b6bfce;
        margin-top: 11px;
        justify-content: space-between;

        > p {
          flex: 1;
        }
      }

      .span1 {
        display: inline-block;
        background-color: #ff7e00;
        width: 8px;
        height: 8px;
        margin-right: 10px;
      }

      .span2 {
        flex: 1;
        text-align: end;
      }
    }

    .top-right {
      font-size: 1.5rem;
      background: linear-gradient(135deg, #fefeff 0%, #cfe7fe 100%);
      flex:1;
      display: flex;
      flex-wrap: wrap;
      padding-top: 30px;
      padding-bottom: 30px;
      .box-nr {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;
        height: 50%;
         @media screen and(max-width: 1600px) {
          width: 33%;
          height: 100px;
        }
        @media screen and(max-width: 1400px) {
          width: 50%;
          height: 100px;
        }
        .imgbox {
          width: 3rem;
          height: 3rem;
          background-color: #3d72ff;
          margin-right: 10px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .imgc {
            width: 2rem;
            height: 2rem;
          }
        }

        .top-li {
          width: 80px;
          font-size: 13px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .nr-z {
    margin-top: 10px;
    background-color: #ffffff;
    padding-top: 40px;
    @media screen and(max-width: 1280px) {
      min-height: 400px;
    }
    .nr-s {
      padding-right: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 40px;
      @media screen and(max-width: 768px) {
        flex-direction: column;
      }
    }

    .nrx-box {
      display: flex;
      padding-right: 20px;
      padding-bottom: 33px;
      @media screen and(max-width: 1280px) {
        flex-wrap: wrap;
      }
      .nr-x {
        padding-top: 20px;
        padding-left: 20px;
        padding-bottom: 20px;
        flex: 1;
        margin-left: 20px;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.07);
        min-width: 200px;
        border-radius: 6rpx;
        @media screen and(max-width: 1280px) {
          flex: none;
          width: 100%;
          margin-bottom: 10px;
        }
        &:hover {
          cursor: pointer;
          background-color: rgb(237, 241, 245, 0.5);
        }
        .nr-x1 {
          font-size: 16px;
          margin-bottom: 21px;
        }

        .nr-x2 {
          display: flex;
          align-items: center;
          font-size: 24px;
          margin-bottom: 5px;

          img {
            width: 26px;
            height: 28px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .s-wrap {
    min-width: 500px;
    overflow-x: auto;
    width: 100%;
    height: 250px;
  }
  .s-item {
    height: 30px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    font-size: 14px;
    align-items: center;
    color: #434343;
    border-bottom: 1px solid #f8f8f8;
    @media screen and(max-width: 1280px) {
      font-size: 10px;
    }
    p {
      flex: 1;
      text-align: center;
    }
  }
  .s-head {
    background-color: #f8f8f8;
    font-size: 16px;
    @media screen and(max-width: 1280px) {
      font-size: 12px;
    }
  }

  .static-section {
    margin-top: 20px;
    flex-wrap: wrap;
    .table-box {
      padding: 0 30px;
    }
    .line {
      width: 1px;
      height: 58px;
      background-color: #e9e9e9;
    }
    .section-left {
      width: 70%;
      margin-right: 20px;
      background-color: #fff;
      @media screen and(max-width: 1280px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    .box-wrap {
      padding: 36px 20px;
      background-color: #fff;
      border-radius: 4px;
      margin-bottom: 20px;
    }
    .box-title {
      font-size: 18px;
      color: #434343;
    }
    .box-main {
      margin-top: 30px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
    }
    .section-right {
      width: calc(30% - 22px);
      @media screen and(max-width: 1280px) {
        width: 100%;
      }
    }
    .info-item {
      height: 52px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    // .info-item + .info-item {
    //   border-left: 1px solid #e9e9e9;
    // }
    .info-title {
      font-size: 16px;
      color: #808080;
      text-align: center;
    }
    .info-num {
      font-size: 22px;
      color: #333333;
      text-align: center;
    }
  }
  .sub-title {
    font-size: 16px;
    color: #525252;
    margin: 20px 0;
  }
  .circle-t {
    width: 8px;
    height: 8px;
    background: #ff7e00;
    border-radius: 50%;
    margin-right: 10px;
  }
  .nr-section {
    margin-top: 10px;

    .wrap-box {
      @media screen and(max-width: 1280px) {
        width: 100%;
        flex: none !important;
        margin-bottom: 10px;
      }
    }

    .nr-section-s {
      display: flex;
      @media screen and(max-width: 1280px) {
        flex-wrap: wrap;
      }
      .nr-section-left {
        width: 910px;
        // flex: 1;
        min-height: 170px;
        background-color: #ffffff;
        padding-top: 36px;
        @media screen and(max-width: 1280px) {
          width: 100%;
        }

        > P {
          margin-left: 20px;
          font-size: 18px;
          margin-bottom: 36px;
        }

        .left-ul {
          display: flex;
          flex-wrap: wrap;

          > li {
            flex: 1;
            @media screen and(max-width: 768px) {
              flex: none;
              width: 49%;
            }

            &:not(:last-child) {
              height: 60px;
              border-right: 1px solid #e9e9e9;
            }

            > p {
              margin-bottom: 10px;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .nodata {
    text-align: center;
    font-size: 16px;
    margin: 10px 0;
    color: rgb(104, 102, 102);
  }
}

.li-item {
  width: 250px;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #b6bfce;
  margin-top: 11px;
  justify-content: space-between;
}
</style>
  